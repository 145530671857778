import React, { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faYoutube} from '@fortawesome/free-brands-svg-icons'
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import {Helmet} from 'react-helmet'
const Arugot = () => {


    const form = useRef();

  const [info, setinfo] = useState({name : "", email : "", message : ""})
const [errormes, seterrormes] = useState("")

  const sendEmail = (e) => {
    e.preventDefault();
if (info.name != "" && info.email != "" && info.message != "")
{
  emailjs
      .sendForm(
        "service_pe5hht6",
        "template_zgftpni",
        form.current,
        "L-N_ExUylATdXKtA5"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          seterrormes("")
          form.current.reset()
          alert("message sent")

        },
        (error) => {
          console.log(error.text);
        }
      );
}
else
{
  seterrormes("יש למלא את כל השדות")
  console.log("does not send")
}
    
  };


  const [isPhoneScreen3, setIsPhoneScreen3] = useState(window.innerWidth <= 425);
  const [isPhoneScreen, setIsPhoneScreen] = useState(window.innerWidth <= 425);
  const [isPhoneScreen4, setIsPhoneScreen4] = useState(window.innerWidth <= 425);

  
    const handleResize = () => {
      setIsPhoneScreen(window.innerWidth <= 1337);
      setIsPhoneScreen3(window.innerWidth <=639);
      setIsPhoneScreen4(window.innerWidth <=650);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  

     useEffect(() => {
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [isPhoneScreen,isPhoneScreen3,isPhoneScreen4]);
  
  
  return (
    <div>
      <Helmet>
        <title>נינג'ה פאוור-סניף ערוגות</title>
        <meta name="description" content="נינג'ה פאוור-ברוכים הבאים לסניף ערוגות" />
     
      </Helmet>
        <br/> <br/> 
      
        <div className='arugot'>
        <br/> 
       <p className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl" style={{color : "white", textAlign : "center"}}>
          נינג'ה פאוור סניף ערוגות
          </p>
          <br/>  
          <p className='fontarugot'>
          נינג'ה פאוור סניף ערוגות הוא האח התאום של סניף גן יבנה
       גם פה תוכלו למצוא מתחם נינג'ה מקצועי - 
       המתחם מפעיל חוגי נינג'ה, מקנה הרגלי ספורט נכונים,
        מלמד את הטכניקות והמיומניות בצורה הכי מקצועית ומהנה שיש
      </p>
    
      <br/> <br/> <br/> 
      <div className='pic'>
          <img src='https://live.staticflickr.com/65535/53074855034_2ae4fea101_n.jpg' alt='נינגה פאוור ערוגות' className='teampic'/>
          &nbsp; &nbsp;
          <img src='https://live.staticflickr.com/65535/53074081882_482264e312_n.jpg'  alt='נינגה פאוור ערוגות' className='teampic'/>
          {/* &nbsp; &nbsp;
          <img src='https://live.staticflickr.com/65535/53074658856_353da6b7bb_n.jpg' style={{width : "300px", height : "300px"}}/> */}
          &nbsp; &nbsp;
          <img src='https://live.staticflickr.com/65535/53075163598_a07e4e794a_n.jpg'  alt='נינגה פאוור ערוגות' className='teampic'/>
          &nbsp; &nbsp;
          {
            !isPhoneScreen && 
            <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4035.3297966045106!2d34.779409870211744!3d31.735165358452633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1502be7541e5bfff%3A0xd8f5792bfdb93756!2sCultural%20center!5e0!3m2!1siw!2sil!4v1690469610219!5m2!1siw!2sil"
             width="300"
             height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Embedded Google Map"
            ></iframe>

          }




          </div>
       </div>
       

<div style={{backgroundColor : "#2c2b42"}}>
       <h6 className="text-4xl font-bold text-gray-900" style={{textAlign : "center", color : "red"}}>מערכת שעות נינג'ה פאוור ערוגות</h6>
    <br/> <br/> 
    <table className='tab'>
    <tbody>
    <th style={{ color : "white"}} className='fontth'>שישי</th> 
    <th  style={{ color : "white"}}  className='fontth'>חמישי</th><th  style={{ color : "white"}}  className='fontth'>רביעי</th>
    <th  style={{ color : "white"}}  className='fontth'>שלישי</th><th  style={{ color : "white"}}  className='fontth'>שני</th>  
    <br/>
  <tr className='tr'>
  <td >12:30-13:25
  <p style={{color : "rgb(91, 190, 66)"}}>א-ג</p>
  </td>
    <td>16:30-17:25
        <p style={{color : "rgb(91, 190, 66)"}}>א-ג</p>
    </td>
    <td>16:00-16:55
        <p style={{color : "rgb(91, 190, 66)"}}>ד-ו</p></td>
    <td>15:30-16:25
        <p style={{color : "rgb(91, 190, 66)"}}>א-ג</p></td>
    <td>16:00-16:55
        <p style={{color : "rgb(91, 190, 66)"}}>ד-ו</p>
    </td>
  </tr>
  <br/>
  <tr className='tr'> 
  <td>13:30-14:25
  <p style={{color : "rgb(91, 190, 66)"}}>גנים + א</p>
  </td>
    <td>17:30-18:25
        <p style={{color : "rgb(91, 190, 66)"}}>ד-ו</p>
    </td>
    <td>17:00-17:55
        <p style={{color : "rgb(91, 190, 66)"}}>ד-ו</p></td>
    <td>18:30-19:25
        <p style={{color : "rgb(91, 190, 66)"}}>ד-ו</p></td>
    <td>17:00-17:55
        <p style={{color : "rgb(91, 190, 66)"}}>ד-ו</p>
    </td>
  </tr>
  <br/>
  <tr className='tr'>
  <td></td>
  <td>18:30-19:25
        <p style={{color : "rgb(91, 190, 66)"}}>ד-ו</p>
    </td>
    <td>18:00-18:55
        <p style={{color : "rgb(91, 190, 66)"}}>נבחרת - כוח</p>
    </td>
    <td></td>
    <td>18:00-18:55
        <p style={{color : "rgb(91, 190, 66)"}}>נבחרת</p>
    </td>
  </tr >
  <br/>
  
  </tbody> 
</table>
</div>
    
     
   
    <h2 className="text-center text-lg font-semibold leading-8 text-gray-900"
style={{backgroundColor : "#2c2b42", color : "red"}}>
        התחילו איתנו את המסע לאלופי נינג'ה
        </h2>
    <div className='contact-container'>
  {
    !isPhoneScreen4 &&
    <div className='right-side-text'> 
    <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4035.3297966045106!2d34.779409870211744!3d31.735165358452633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1502be7541e5bfff%3A0xd8f5792bfdb93756!2sCultural%20center!5e0!3m2!1siw!2sil!4v1690469610219!5m2!1siw!2sil"
    
    className='map'
    style={{ border: 0 }}
    allowFullScreen=""
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
    title="Embedded Google Map"
    ></iframe>
      </div>
  }
 
 
    <div className="form-content">
    <form ref={form} onSubmit={sendEmail}>
        <div className="form-group">
          <input type="text" id="name" name="to_name" placeholder='שם מלא' onChange={(e)=> setinfo({...info, name : e.target.value})}/>
        </div>
        <div className="form-group">
          <input type="email" id="email" name="from_name"  placeholder='אימייל' onChange={(e)=> setinfo({...info, email : e.target.value})} />
        </div>
        <div className="form-group">
          <textarea id="message" name="message"  placeholder='הודעה' onChange={(e)=> setinfo({...info, message : e.target.value})}></textarea>
        </div>
        &nbsp; &nbsp;&nbsp; &nbsp;
        <button type="submit" value="Send" >צאו לדרך</button>
        <br/>
        <p style={{color : "red", backgroundColor : "white"}}>{errormes}</p>
        </form>
      </div>
  
   </div>

   <br/><br/>
     
     {/*final part */}


      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
        <a href='https://api.whatsapp.com/send?phone=972522257838&text=Hello%20there!'>
  <FontAwesomeIcon icon={faWhatsapp} style={{width : "50px", height : "50px", color : "green"}} />
  </a>

        <a href='https://www.facebook.com/profile.php?id=100076104404400'>
<FontAwesomeIcon icon={faFacebook} style={{width : "50px", height : "50px", color : "blue"}} />
</a>

<a href='https://www.instagram.com/ninjapower_ganyavne/'>
<FontAwesomeIcon icon={faInstagram} style={{width : "50px", height : "50px", color : "black"}} />
</a>

  <a href='https://www.youtube.com/watch?v=DeKX_kdKBYw&feature=youtu.be'> 
  <FontAwesomeIcon icon={faYoutube} style={{width : "50px", height : "50px", color : "red"}}/>
  </a>

{
  !isPhoneScreen3 &&
  <a href="https://www.google.com/maps/place/%D7%A0%D7%99%D7%A0%D7%92'%D7%94+%D7%A4%D7%90%D7%95%D7%95%D7%A8+%D7%92%D7%9F+%D7%99%D7%91%D7%A0%D7%94%E2%80%AD/@31.7745304,34.7054061,17z/data=!3m1!4b1!4m6!3m5!1s0x1502bd92043d70fd:0x5e83c54e64f113c1!8m2!3d31.7745304!4d34.7028312!16s%2Fg%2F11pklv56lh?hl=iw&entry=ttu" 
          target="_blank" rel="noopener noreferrer">
           <img
          style={{width : "75px", height : "75px"}}
          src="https://www.techgadgetscanada.com/wp-content/uploads/2017/05/Google-Maps-2-.png"
          alt="google maps"
         
        /> </a>
}
          
        </div>
        </div>




        </div>
       
  )
}

export default Arugot