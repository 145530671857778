import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Finaldet from './Finaldet';
import {Helmet} from 'react-helmet'
const Trainings = () => {
 
  const stordata = useSelector(state => state.trainings)
  const stordata2 = useSelector(state => state.trainingspics)

function createData(name, hour12, hour14, hour16, hour18,hour20,hour21,hour22) {
  return { name, hour12, hour14, hour16, hour18,hour20,hour21,hour22 };
}

const rows = [
  createData("open gym 17:22:00", "open gym 14:00-16:00", "15:00-16:30 A נבחרת ", " "," "," ","כיתות ד-ו",'15:30-16:20'),
  createData( "open gym 17:22:00", "open gym 14:00-16:00", "כיתות ד-ו ", " כיתות ב-ד","כיתות ב-ד "," גנים + א","כיתות ב-ד",'16:30-17:15'),
  createData( "open gym 17:22:00", "open gym 14:00-16:00", "כיתות ב-ד ", " גנים + א"," 16:15-17:15 B נבחרת  ","  כיתות ב-ד "," גנים + א",'17:30-18:15'),
  createData( "open gym 17:22:00", "open gym 14:00-16:00", " כיתות ז-ט", " כיתות ד-ו"," כיתות ז-ט"," כיתות ד-ו ","כיתות ד-ו",'18:20/30-19:20',),
  createData( "open gym 17:22:00", "open gym 14:00-16:00", "16+", " כיתות ז-ט חיזוק"," 16+"," "," נבחרת עלית ",'19:30-20:30'),
  createData("open gym 17:22:00", "open gym 14:00-16:00", "", ""," בוגרים"," ","נבחרת עלית",'20:30-21:30'),
 
];


  return (
    <div>
       <Helmet>
        <title>נינג'ה פאוור-אימונים</title>
        <meta name="description" content="נינג'ה פאוור-אימונים שדוחפים אותך לקצה" />
       
      </Helmet>
        
          <br/> <br/> 
          <div className="bg-gray-100" style={{backgroundColor : "#2c2b42"}}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
          <h2 className="text-4xl font-bold text-gray-900" style={{textAlign : "center", color : "red"}}>מגוון רחב של אימונים </h2>

          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {stordata.map((x,index) => (
              <div key={index} className="group relative">
                 <div>
               <h1 style={{textAlign : "center", fontSize : "20px", color : "red"}}>
                 {x.type}
                </h1>
               </div>
                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                  <img
                    src={x.typepic}
                    alt="אימונים נינג'ה פאוור"
                    className="h-full w-full object-cover object-center"
                  />
                </div>
              
                
                <br/>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    
    {/*table*/}
   <div style={{backgroundColor : "#2c2b42"}}>

  
<h6 className="text-4xl font-bold text-gray-900" style={{textAlign : "center", color : "white"}}>מערכת שעות נינג'ה פאוור גן יבנה</h6>
    <br/> <br/> 
   <div>
    <TableContainer component={Paper} style={{backgroundColor : "black", border: '3px solid red'}} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{color : "white", backgroundColor : "red"}}>שבת</TableCell>
            <TableCell align="right" style={{color : "white", backgroundColor : "red"}}>שישי</TableCell>
            <TableCell align="right" style={{color : "white", backgroundColor : "red"}}>חמישי</TableCell>
            <TableCell align="right" style={{color : "white", backgroundColor : "red"}}>רביעי</TableCell>
            <TableCell align="right" style={{color : "white", backgroundColor : "red"}}>שלישי</TableCell>
            <TableCell align="right" style={{color : "white", backgroundColor : "red"}}>שני</TableCell>
            <TableCell align="right" style={{color : "white", backgroundColor : "red"}}>ראשון</TableCell>
            <TableCell align="right" style={{color : "white", backgroundColor : "red"}}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,index2) => (
            <TableRow
              key={index2}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{color : "white"}}>
                {row.name}
              </TableCell>
              <TableCell align="right" style={{color : "white"}}>{row.hour12}</TableCell>
              <TableCell align="right" style={{color : "white"}}>{row.hour14}</TableCell>
              <TableCell align="right" style={{color : "white"}}>{row.hour16}</TableCell>
              <TableCell align="right" style={{color : "white"}}>{row.hour18}</TableCell>
              <TableCell align="right" style={{color : "white"}}>{row.hour20}</TableCell>
              <TableCell align="right" style={{color : "white"}}>{row.hour21}</TableCell>
              <TableCell align="right" style={{color : "white"}}>{row.hour22}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>

    <br/> <br/> 

{/*catalog*/}

 <div className="bg-white" style={{backgroundColor : "#2c2b42"}}>
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
       

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
          {stordata2.map((x,index) => (
            <a key={index} className="group">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={x.bodyurl}
                  alt="נינג'ה פאוור גן יבנה"
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
    </div>
   
    <Finaldet/>

  

    </div>
  )
}

export default Trainings