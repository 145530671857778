
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import Homepage from './Homepage';
import About from './About';
import Trainings from './Trainings';
import Events from './Events';
import Team from './Team';
import { useEffect, useState } from 'react';
import {db} from '../Firebase'
import { collection, getDocs } from 'firebase/firestore'
import {useDispatch, useSelector} from 'react-redux'
import Makecontact from './Makecontact';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faYoutube} from '@fortawesome/free-brands-svg-icons'
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import Arugot from './Arugot';
import Reviews from './Reviews';
import { MenuItem, Select } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';



const Main = () => {

  const [details, setdetails] = useState([])
  const [events, setevents] = useState([])
  const [gympics, setgympics] = useState([])
  const [leaders, setleaders] = useState([])
  const [trainingpics, settrainingpics] = useState([])
  const [trainings, settrainings] = useState([])
  const [values, setvalues] = useState([])
  const [reviews, setreviews] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const stordata = useSelector(state => state)

  const [color1, setcolor1] = useState(true)
  const [color2, setcolor2] = useState(false)
  const [color3, setcolor3] = useState(false)
  const [color4, setcolor4] = useState(false)
  const [color5, setcolor5] = useState(false)
  const [color6, setcolor6] = useState(false)
  const [color7, setcolor7] = useState(false)
  const [color8, setcolor8] = useState(false)

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showx, setShowx] = useState(false);

  const [isPhoneScreen, setIsPhoneScreen] = useState(window.innerWidth <= 425);
  
  const handleResize = () => {
    setIsPhoneScreen(window.innerWidth <= 1203);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isPhoneScreen]);

  const showmenu = () =>
  {
    setShowMobileMenu(!showMobileMenu)
    setShowx(!showx)
  }

  useEffect(()=>
  {
    const getrooms = async () =>
    {
      //details
      const det = await getDocs(collection(db, "details"));
      let detdata = []
  
      det.forEach(doc => 
      {
        let obj = {id : doc.id, about : doc.data().about, story : doc.data().story, video : doc.data().video, storydata : doc.data().storydata};
          detdata.push(obj)
          dispatch({type : "LOAD" , payload : detdata})
      });

     

      //events
      const eve = await getDocs(collection(db, "events"));
      let evedata = []
  
      eve.forEach(doc => 
      {
        let obj2 = {id : doc.id, body : doc.data().body, type : doc.data().type, video : doc.data().video};
          evedata.push(obj2)
          dispatch({type : "LOAD2" , payload : evedata})
      });

      //gympics
      const gym = await getDocs(collection(db, "gympics"));
      let gymdata = []
  
      gym.forEach(doc => 
      {
        let obj3 = {id : doc.id, bodyurl : doc.data().bodyurl };
          gymdata.push(obj3)
          dispatch({type : "LOAD3" , payload : gymdata})
      });

      //leaders
      const lead = await getDocs(collection(db, "leaders"));
      let leaddata = []
  
      lead.forEach(doc => 
      {
        let obj4 = {id : doc.id, body : doc.data().body, fullname : doc.data().fullname, picture : doc.data().picture };
          leaddata.push(obj4)
          dispatch({type : "LOAD4" , payload : leaddata})
      });

      //trainingpics
      const tpics = await getDocs(collection(db, "trainingpics"));
      let tpicsdata = []
  
      tpics.forEach(doc => 
      {
        let obj5 = {id : doc.id, bodyurl : doc.data().bodyurl, };
          tpicsdata.push(obj5)
          dispatch({type : "LOAD5" , payload : tpicsdata})
      });
  
      //trainings

      const training = await getDocs(collection(db, "trainings"));
      let trainingdata = []
  
      training.forEach(doc => 
      {
        let obj6 = {id : doc.id, type : doc.data().type, typeinfo : doc.data().typeinfo, typepic : doc.data().typepic };
         trainingdata.push(obj6)
         dispatch({type : "LOAD6" , payload : trainingdata})
      });

       //values

       const values2 = await getDocs(collection(db, "values"));
       let valuesdata = []
   
       values2.forEach(doc => 
       {
         let obj7 = {id : doc.id, description : doc.data().description, name : doc.data().name };
          valuesdata.push(obj7)
          dispatch({type : "LOAD7" , payload : valuesdata})
       });

        //reviews
       const revs = await getDocs(collection(db, "reviews"));
         let revdata = []

         revs.forEach(doc => 
          {
             let obj8 = {id : doc.id, firstname : doc.data().firstname, lastname : doc.data().lastname, message : doc.data().message, time : doc.data().time};
                revdata.push(obj8)
               dispatch({type : "LOAD8" , payload : revdata})
                });


      //set
      setdetails(detdata)
      setevents(evedata)
      setgympics(gymdata)
      setleaders(leaddata)
      settrainingpics(tpicsdata)
      settrainings(trainingdata)
      setvalues(valuesdata)
      setreviews(revdata)
      // console.log( detdata, evedata, gymdata, leaddata, tpicsdata, trainingdata,revdata)
    }
  getrooms()
  
  navigate('/')

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
  
  },[])
  



  const next1 = () =>
  {
    setcolor1(true)
    setcolor2(false)
    setcolor3(false)
    setcolor4(false)
    setcolor5(false)
    setcolor6(false)
    setcolor7(false)
    setcolor8(false)
    setShowMobileMenu(false)
    setShowx(false)
  }

  const next2 = () =>
  {
    setcolor1(false)
    setcolor2(true)
    setcolor3(false)
    setcolor4(false)
    setcolor5(false)
    setcolor6(false)
    setcolor7(false)
    setcolor8(false)
    setShowMobileMenu(false)
    setShowx(false)
  }

  const next3 = () =>
  {
    setcolor1(false)
    setcolor2(false)
    setcolor3(true)
    setcolor4(false)
    setcolor5(false)
    setcolor6(false)
    setcolor7(false)
    setcolor8(false)
    setShowMobileMenu(false)
    setShowx(false)
  }

  const next4 = () =>
  {
    setcolor1(false)
    setcolor2(false)
    setcolor3(false)
    setcolor4(true)
    setcolor5(false)
    setcolor6(false)
    setcolor7(false)
    setcolor8(false)
    setShowMobileMenu(false)
    setShowx(false)
  }

  const next5 = () =>
  {
    setcolor1(false)
    setcolor2(false)
    setcolor3(false)
    setcolor4(false)
    setcolor5(true)
    setcolor6(false)
    setcolor7(false)
    setcolor8(false)
    setShowMobileMenu(false)
    setShowx(false)
  }

  const next6 = () =>
  {
    setcolor1(false)
    setcolor2(false)
    setcolor3(false)
    setcolor4(false)
    setcolor5(false)
    setcolor6(true)
    setcolor7(false)
    setcolor8(false)
    setShowMobileMenu(false)
    setShowx(false)
  }

  const next7 = () =>
  {
    setcolor1(false)
    setcolor2(false)
    setcolor3(false)
    setcolor4(false)
    setcolor5(false)
    setcolor6(false)
    setcolor7(true)
    setcolor8(false)
    setShowMobileMenu(false)
    setShowx(false)
  }

  const next8 = () =>
  {
    setcolor1(false)
    setcolor2(false)
    setcolor3(false)
    setcolor4(false)
    setcolor5(false)
    setcolor6(false)
    setcolor7(false)
    setcolor8(true)
    setShowMobileMenu(false)
    setShowx(false)
  }


  const sendbyimg = () =>
  {
    navigate('/')
    setcolor1(true)
    setcolor2(false)
    setcolor3(false)
    setcolor4(false)
    setcolor5(false)
    setcolor6(false)
    setcolor7(false)
    setcolor8(false)
  }
  
  return (
    <div>
    <div className='top'>
      {
         !isPhoneScreen &&
         <div className="line-container">
         <Link to={'/'}><h4 onClick={next1} className={color1? 'red' : 'white'}>עמוד הבית</h4></Link>
         <Link to={'/אודות'}> <h4 onClick={next2} className={color2? 'red' : 'white'}>אודות</h4></Link>
         <Link to={'/אימונים'}><h4 onClick={next3} className={color3? 'red' : 'white'}>אימונים</h4></Link>
         <Link to={'/אירועים'}><h4 onClick={next4} className={color4? 'red' : 'white'}>אירועים</h4></Link>
         <Link to={'/צוות'}><h4 onClick={next5} className={color5? 'red' : 'white'}>הצוות שלנו</h4></Link>
         <Link to={'/צורקשר'}><h4 onClick={next6} className={color6? 'red' : 'white'}>צרו קשר</h4></Link>
         <Link to={'/ערוגות'}><h4 onClick={next7} className={color7? 'red' : 'white'}>סניף ערוגות</h4></Link>
         <Link to={'/העמודשלכם'}><h4 onClick={next8} className={color8? 'red' : 'white'}>העמוד שלכם</h4></Link>
         {/* <div style={{fontSize : "30px"}}>&#9776;</div> */}

         <div className='line-container2'>
<img src='https://live.staticflickr.com/65535/53105695362_78385f9824_n.jpg' 
style={{width : "60px", height : "60px"}} onClick={sendbyimg} />

<a href='https://www.facebook.com/profile.php?id=100076104404400'>
<FontAwesomeIcon icon={faFacebook} style={{width : "30px", height : "30px", color : "white"}} />
</a>

<a href='https://www.instagram.com/ninjapower_ganyavne/'>
<FontAwesomeIcon icon={faInstagram} style={{width : "30px", height : "30px", color : "white"}} />
</a>

  <a href='https://www.youtube.com/watch?v=DeKX_kdKBYw&feature=youtu.be'> 
  <FontAwesomeIcon icon={faYoutube} style={{width : "30px", height : "30px", color : "white"}}/>
  </a>
  
  <a href='https://api.whatsapp.com/send?phone=972522257838&text=Hello%20there!'>
  <FontAwesomeIcon icon={faWhatsapp} style={{width : "30px", height : "30px", color : "white"}} />
  </a>
  
</div>
     </div>
      }
       
    {
      isPhoneScreen &&
      <div className="line-container">
        {
          !showx && 
          <p style={{fontSize : "40px"}} onClick={showmenu}>&#9776;</p>
        }
         
         {
          showx && 
         <p onClick={showmenu}><ClearIcon/></p>
         }
         &nbsp; &nbsp;
         <div className='line-container2'>
<img src='https://live.staticflickr.com/65535/53105695362_78385f9824_n.jpg' 
style={{width : "60px", height : "60px"}} onClick={sendbyimg} />

<a href='https://www.facebook.com/profile.php?id=100076104404400'>
<FontAwesomeIcon icon={faFacebook} style={{width : "30px", height : "30px", color : "white"}} />
</a>

<a href='https://www.instagram.com/ninjapower_ganyavne/'>
<FontAwesomeIcon icon={faInstagram} style={{width : "30px", height : "30px", color : "white"}} />
</a>

  <a href='https://www.youtube.com/watch?v=DeKX_kdKBYw&feature=youtu.be'> 
  <FontAwesomeIcon icon={faYoutube} style={{width : "30px", height : "30px", color : "white"}}/>
  </a>
  
  <a href='https://api.whatsapp.com/send?phone=972522257838&text=Hello%20there!'>
  <FontAwesomeIcon icon={faWhatsapp} style={{width : "30px", height : "30px", color : "white"}} />
  </a>
  
</div>


      </div> 
      
    }
   
     {
      showMobileMenu &&
      <div className='right-top-div ' >
<ul>
  <li><Link to={'/'}><h4 onClick={next1} className={color1? 'red' : 'white'}>עמוד הבית</h4></Link></li>
  <li><Link to={'/אודות'}> <h4 onClick={next2} className={color2? 'red' : 'white'}>אודות</h4></Link></li>
  <li><Link to={'/אימונים'}><h4 onClick={next3} className={color3? 'red' : 'white'}>אימונים</h4></Link></li>
  <li><Link to={'/אירועים'}><h4 onClick={next4} className={color4? 'red' : 'white'}>אירועים</h4></Link></li>
  <li><Link to={'/צוות'}><h4 onClick={next5} className={color5? 'red' : 'white'}>הצוות שלנו</h4></Link></li>
  <li><Link to={'/צורקשר'}><h4 onClick={next6} className={color6? 'red' : 'white'}>צרו קשר</h4></Link></li>
  <li><Link to={'/ערוגות'}><h4 onClick={next7} className={color7? 'red' : 'white'}>סניף ערוגות</h4></Link></li>
  <li><Link to={'/העמודשלכם'}><h4 onClick={next8} className={color8? 'red' : 'white'}>העמוד שלכם</h4></Link></li>
</ul>
      </div>
     }
  


</div>

<div>

<Routes>

<Route path="/" element={<Homepage/>} />
<Route path="אודות" element={<About/>} />
<Route path="אימונים" element={<Trainings/>} />
<Route path="אירועים" element={<Events/>} />
<Route path="צוות" element={<Team/>} />
<Route path="צורקשר" element={<Makecontact/>} />
<Route path="ערוגות" element={<Arugot/>} />
<Route path="העמודשלכם" element={<Reviews/>} />

</Routes>

</div>


    </div>
  )
}

export default Main