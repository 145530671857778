import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import YouTube from 'react-youtube';
import Finaldet from './Finaldet';
import {Helmet} from 'react-helmet'

const About = () => {

  const stordata = useSelector(state => state.details)
  const stordata2 = useSelector(state => state.values)

  const youtubeVideoId = 'DeKX_kdKBYw';

  const YouTubeVideo = ({ videoId }) => {
    const opts = {
      height: '700',
      width: '100%',
      playerVars: {
        // Add any additional YouTube player parameters here (optional)
        autoplay: 0,
      },
    };
  
    return <YouTube videoId={videoId} opts={opts} />;
  };


  return (
    <div>
       
       <Helmet>
        <title>אודות-נינג'ה פאוור</title>
        <meta name="description" content="נינג'ה פאוור אודות המכון" />
  
      </Helmet>

        {/*about */}
        <div className="bg-white">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" style={{textAlign : "center"}}>אודות</h2>
          <p className="mt-4 text-gray-500" style={{color : "black"}}>
          {stordata.map((x,index)=>
          {
            return <div key={index}>
               <h4 style={{textAlign : "right", fontFamily : "'Trebuchet MS', sans-serif"}}>{x.storydata}</h4> 
            </div>
          })}
          </p>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
             {stordata2.map((x,index) => (
              <div key={index} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900" style={{textAlign : "right"}}>{x.name}</dt>
                <dd className="mt-2 text-sm text-gray-500" style={{textAlign : "right"}}>{x.description}</dd>
              </div>
            ))} 
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          <img
            src="https://live.staticflickr.com/65535/53063391314_0a05f477bc_n.jpg"
            alt="פיינליסט נינג'ה ישראל"
            className="rounded-lg bg-gray-100"
          />
          <img
            src="https://live.staticflickr.com/65535/53063698948_c36b4fbff3_n.jpg"
            alt="פיינליסט נינג'ה ישראל"
            className="rounded-lg bg-gray-100"
          />
          <img
            src="https://live.staticflickr.com/65535/53063698943_5b9bb398a4_n.jpg"
            alt="פיינליסט נינג'ה ישראל"
            className="rounded-lg bg-gray-100"
          />
          <img
            src="https://live.staticflickr.com/65535/53063596415_8838eebac6_n.jpg"
            alt="פיינליסט נינג'ה ישראל"
            className="rounded-lg bg-gray-100"
          />
        </div>
      </div>
    </div>   

    
        {/*video */}
        <div>
     
       <YouTubeVideo videoId={youtubeVideoId} />
    </div>
    

<Finaldet />





    </div>

  )
}

export default About