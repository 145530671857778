
import React, { useEffect, useState } from 'react'
import {db} from '../Firebase'
import { collection, getDocs } from 'firebase/firestore'
import {useDispatch, useSelector} from 'react-redux'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import Finaldet from './Finaldet';
import ImageSlider from './ImageSlider';
import { Helmet } from 'react-helmet';

const Homepage = () => {

   const details = useSelector(state=>state.details)

   const slides = [
    { url: "https://live.staticflickr.com/65535/53063699873_9e1e620783_n.jpg" },
    { url: "https://live.staticflickr.com/65535/53063699893_8cb7271617_n.jpg" },
    { url: "https://live.staticflickr.com/65535/53063597415_3e75dd1b59_w.jpg" },
    { url: "https://live.staticflickr.com/65535/53063209241_c40315354d_w.jpg"},
    // { url: "https://live.staticflickr.com/65535/53063209136_779fab0c63.jpg"},
    { url: "https://live.staticflickr.com/65535/53063597310_63b9e67695_w.jpg"},
  ];
  const containerStyles = {
    width: "100%",
    height: "500px",
    margin: "0 auto",
  };
  
   

    const [isPhoneScreen, setIsPhoneScreen] = useState(window.innerWidth <= 425);
    const [isPhoneScreen2, setIsPhoneScreen2] = useState(window.innerWidth <= 425);
    const [isPhoneScreen3, setIsPhoneScreen3] = useState(window.innerWidth <= 425);
    const [isPhoneScreen4, setIsPhoneScreen4] = useState(window.innerWidth <= 425);

    const handleResize = () => {
      setIsPhoneScreen(window.innerWidth <= 655);
      setIsPhoneScreen2(window.innerWidth >=413);
      setIsPhoneScreen3(window.innerWidth <=412);
      setIsPhoneScreen4(window.innerWidth <=1337);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  

     useEffect(() => {
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [isPhoneScreen,isPhoneScreen2,isPhoneScreen3]);
  


  return (
    <div>
 <br/><br/><br/>
 <Helmet>
        <title>עמוד הבית-נינג'ה פאוור</title>
        <meta name="description" content="נינג'ה פאוור עמוד הבית" />
      </Helmet>
 
 
{
  details.map((x,index)=>
  {
    return <div key={index}>


      <div className="relative overflow-hidden bg-white" style={{textAlign : "right", backgroundColor : "#2c2b42"}}>  
      <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
        <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
          {
            !isPhoneScreen4 &&
             <div className="sm:max-w-lg" style={{textAlign : "center"}}>
             <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" style={{color : "red"}}>
             נינג'ה פאוור 
             </h1>
             <p className="mt-4 text-xl text-gray-500" style={{color : "white", fontFamily : "Georgia, serif"}}>
              {x.about}
             </p>
           </div>
  }

{
            isPhoneScreen4 &&
             <div className="sm:max-w-lg"  style={{textAlign: "center", margin: "0 auto", display: "grid", placeItems: "center"}}>
             <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" style={{color : "red"}}>
             נינג'ה פאוור 
             </h1>
             <p className="mt-4 text-xl text-gray-500" style={{color : "white", fontFamily : "Georgia, serif"}}>
              {x.about}
             </p>
           </div>
  }


          <div>
     <div>   
       

{
  !isPhoneScreen4 &&

    <div className="mt-10" >
    {/* Decorative image grid */}
    <div
      aria-hidden="true"
      className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
    >
      <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
        <div className="flex items-center space-x-6 lg:space-x-8">
          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
            <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
              <img
                src="https://live.staticflickr.com/65535/53063699873_9e1e620783_n.jpg"
                alt="מכון נינג'ה פאוור גן יבנה"
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="h-64 w-44 overflow-hidden rounded-lg">
              <img
                src="https://live.staticflickr.com/65535/53063699893_8cb7271617_n.jpg"
                alt="מכון נינג'ה פאוור גן יבנה"
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
            <div className="h-64 w-44 overflow-hidden rounded-lg">
              <img
                src="https://live.staticflickr.com/65535/53063597310_63b9e67695_w.jpg"
                alt="מכון נינג'ה פאוור גן יבנה"
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="h-64 w-44 overflow-hidden rounded-lg">
              <img
                src="https://live.staticflickr.com/65535/53063209241_c40315354d_w.jpg"
                alt="מכון נינג'ה פאוור גן יבנה"
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="h-64 w-44 overflow-hidden rounded-lg">
              <img
                src="https://live.staticflickr.com/65535/53063209136_779fab0c63.jpg"
                alt="מכון נינג'ה פאוור גן יבנה"
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
            <div className="h-64 w-44 overflow-hidden rounded-lg">
              <img
                src="https://live.staticflickr.com/65535/53063597415_3e75dd1b59_w.jpg"
                alt="מכון נינג'ה פאוור גן יבנה"
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="h-64 w-44 overflow-hidden rounded-lg">
              <img
                src="https://live.staticflickr.com/65535/53063208236_3c40cbace5_n.jpg"
                alt="מכון נינג'ה פאוור גן יבנה"
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
  </div>   


  


{
  isPhoneScreen4 &&
<div>
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
          {slides.map((x,index) => (
            <a key={index} className="group">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={x.url}
                  alt="מכון נינג'ה פאוור גן יבנה"
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
}
          </div>
        </div>
      </div>
    </div>

<div>

  
{/*who we are */}

<div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
        
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
       
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl" style={{textAlign : "right"}}>אם עדיין לא שמעתם עלינו</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300" style={{textAlign : "right"}}>
           {x.story}
          </p>
        </div>
      </div>
    </div>
            

    

<Finaldet/>

  
    </div>

       </div>
  })
}




    </div>
  )
}

export default Homepage