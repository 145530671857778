import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useSelector } from 'react-redux';
import Finaldet from './Finaldet';
import {Helmet} from 'react-helmet'
const Team = () => {

const stordata = useSelector(state=>state.leaders)

const [isPhoneScreen4, setIsPhoneScreen4] = useState(window.innerWidth <= 425);

  
    const handleResize = () => {
      setIsPhoneScreen4(window.innerWidth <= 900);
    };
  
    
    useEffect(() => {
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
   
     useEffect(() => {
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [isPhoneScreen4]);

  
  return (
    <div>
         <Helmet>
        <title>נינג'ה פאוור-הצוות שלנו</title>
        <meta name="description" content="נינג'ה פאוור-הצוות המקצועי" />
      
      </Helmet>
        
<div className='team'>
<br/> <br/>
<p className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl" style={{color : "red", textAlign : "center"}}>
           הפאוור שבנינג'ה זה הצוות
          </p>
          <br/> <br/> 
{
  !isPhoneScreen4 &&

  <div className='pic'>
            
  <img src='https://live.staticflickr.com/65535/53062609412_dc2407d749_n.jpg' alt="נינג'ה פאוור גן יבנה"  className="teampic"/>
  &nbsp; &nbsp;
  <img src='https://live.staticflickr.com/65535/53063698923_2c4e88e9e4_w.jpg' alt="נינג'ה פאוור גן יבנה"  className="teampic"/>
  &nbsp; &nbsp;
  <img src='https://live.staticflickr.com/65535/53063699363_8439b6bf8e_w.jpg' alt="נינג'ה פאוור גן יבנה" className="teampic"/>
  </div>

}

{
  isPhoneScreen4 && 
  
  <div className='pic'>
  <img src='https://live.staticflickr.com/65535/53063698923_2c4e88e9e4_w.jpg' alt="נינג'ה פאוור גן יבנה"  className="teampic2"/>
  &nbsp; &nbsp;
  <img src='https://live.staticflickr.com/65535/53063699363_8439b6bf8e_w.jpg' alt="נינג'ה פאוור גן יבנה" className="teampic2"/>
  </div>

}

         
          </div>
      
           
          <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
        
        <p className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl" style={{color : "blue", textAlign : "center"}}>
            הצוות שלנו
          </p>
          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {stordata.map((x,index) => (
              <div key={index} className="group relative">
                <p className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl" style={{color : "black", textAlign : "center"}}>
                {x.body} -{x.fullname}
                </p>
                <div >
                  <img
                    src={x.picture}
                    alt="הצוות של נינג'ה פאוור"
                    className="h-full w-full object-cover object-center"
                  />
                </div>
    
                
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <br/> <br/> <br/> <br/> 
    <Finaldet/>
   
    </div>
  )
}

export default Team