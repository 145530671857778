import React from 'react'
import { useSelector } from 'react-redux'
import YouTube from 'react-youtube';
import Finaldet from './Finaldet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import {Helmet} from 'react-helmet'
const Events = () => {

const stordata = useSelector(state=>state.events)

const youtubeVideoId = 'DeKX_kdKBYw';

const YouTubeVideo = ({ videoId }) => {
  const opts = {
    height: '700',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};



  return (
    <div>
           <Helmet>
        <title>נינג'ה פאוור-אירועים</title>
        <meta name="description" content="נינג'ה פאוור-אירועים בלתי נשכחים" />
       
      </Helmet>
        
        <div className="bg-white py-24 sm:py-32" style={{backgroundColor : "#2c2b42"}}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">

          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" style={{color : "white", textAlign : "center"}}>
            אנחנו הופכים כל אירוע לחווית ספורט מאתגרת מגבשת ובלתי נשכחת
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600" style={{color : "red", textAlign : "center"}}>
           אנחנו מציעים מגוון רחב של אירועים במתחם הנינג'ה
          </p>
          <ul className="mt-6 text-lg leading-8 text-gray-600" style={{color : "white", textAlign : "center"}}>
            <li>ימי הולדת</li>
            <li>ימי גיבוש</li>
            <li>תחרויות</li>
            <li>לילה לבן ומחנות קיץ</li>
            <li>ימי העשרה</li>
           </ul>
        </div>

        <div className="bg-gray-100" style={{backgroundColor : "#2c2b42"}}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {stordata.map((x,index) => (
              <div key={index} className="group relative">
                 <div>
               <h1 style={{textAlign : "center", fontSize : "20px", color : "red"}}>
                 {x.type}
                </h1>
                <br/>
               </div>
                 <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                  <img
                     src={x.video} 
                     alt='אירועים נינגה פאוור גן יבנה'
                    className="h-full w-full object-cover object-center"
                  />
                </div> 
              
                  
                <h4 style={{textAlign : "right" ,color : "white", fontSize : "20px"}}  >{x.body}</h4>
                
               
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

        {/* <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl" >
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {stordata.map((x,index) => (
              <div key={index} className="relative pl-16">
                <dt className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl" style={{color : "red", textAlign : "center"}}>
                  {x.type}
                </dt>
                <br/>
                <h4 style={{textAlign : "right" ,color : "white"}}  >{x.body}</h4>
                <br/>
                <img src={x.video} alt='אירועים נינגה פאוור גן יבנה' />
              </div>
              
            ))}
          </dl>
        </div> */}
      </div>
    </div>
    

    <div className="header">
  <h1>?מעוניינים להיות חלק מחוויה בלתי נשכחת</h1>
  <p style={{color : "red"}}>תלחצו על אחד הקישורים למטה וצרו קשר</p>
  

  
<a href='https://www.facebook.com/profile.php?id=100076104404400'>
<FontAwesomeIcon icon={faFacebook} style={{width : "30px", height : "30px", color : "white"}} />
</a>
&nbsp; &nbsp;
<a href='https://www.instagram.com/ninjapower_ganyavne/'>
<FontAwesomeIcon icon={faInstagram} style={{width : "30px", height : "30px", color : "white"}} />
</a>
&nbsp; &nbsp; 
  <a href='https://api.whatsapp.com/send?phone=972522257838&text=Hello%20there!'>
  <FontAwesomeIcon icon={faWhatsapp} style={{width : "30px", height : "30px", color : "white"}} />
  </a>
  


</div>


 {/*video */}
 <div>
     
     <YouTubeVideo videoId={youtubeVideoId} />
  </div>


  <Finaldet/>


        </div>
  )
}

export default Events