import React, { useEffect, useState } from 'react'
import Finaldet from './Finaldet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import {Helmet} from 'react-helmet'
const Makecontact = () => {

  const [isPhoneScreen, setIsPhoneScreen] = useState(window.innerWidth <= 425);
  
  const handleResize = () => {
    setIsPhoneScreen(window.innerWidth <= 622);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isPhoneScreen]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div>
        <Helmet>
        <title>נינג'ה פאוור-צרו קשר</title>
        <meta name="description" content="נינג'ה פאוור-צרו איתנו קשר" />
       
      </Helmet>
        
            <br/> <br/> <br/> <br/> 
       <div className='makecontact'>
       <p className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl" style={{color : "black", textAlign : "center"}}>
            צרו קשר
          </p>
          <br/>  
          <p style={{fontSize : '30px', textShadow : "0 0 3px #FF0000" }}>
         אם יש לכם שאלות או בירורים אל תהססו לפנות אלינו באמצעות פרטי ההתקשרות הבאים
      </p>
      <ul>
        <li style={{color : "blue"}}>Roeepapo@gmail.com : אימייל</li>
        <li style={{color : "blue"}}>טלפון : 0768016749</li>
        <li style={{color : "blue"}}>כתובת : הפלך 1 גן יבנה</li>
      </ul>
      <br/> 
      <a href='https://www.facebook.com/profile.php?id=100076104404400'>
  <FontAwesomeIcon icon={faFacebook} style={{width : "30px", height : "30px", color : "black"}} />
    </a>
    &nbsp; &nbsp;
    <a href='https://www.instagram.com/ninjapower_ganyavne/'>
    <FontAwesomeIcon icon={faInstagram} style={{width : "30px", height : "30px", color : "black"}} />
      </a>
    &nbsp; &nbsp; 
   <a href='https://api.whatsapp.com/send?phone=972522257838&text=Hello%20there!'>
    <FontAwesomeIcon icon={faWhatsapp} style={{width : "30px", height : "30px", color : "black"}} />
    </a>

      <br/> <br/> 
  
       </div>


       {
        isPhoneScreen && 
        <div >
           <br/> <br/> <br/> <br/> 
        </div>
       }
       
       <div className='pic'>
          <img src='https://live.staticflickr.com/65535/53063597315_a28205657d_w.jpg' alt="נינג'ה פאוור גן יבנה" className='teampic'/>
          &nbsp; &nbsp;
          <img src='https://live.staticflickr.com/65535/53063699993_a099c521c3_w.jpg' alt="נינג'ה פאוור גן יבנה" className='teampic'/>
          &nbsp; &nbsp;
          <img src='https://live.staticflickr.com/65535/53062624957_e1ddd8f9ce_w.jpg' alt="נינג'ה פאוור גן יבנה" className='teampic' />
          </div>

       <br/> <br/> <br/> <br/> 

       <Finaldet/>
    {/*contact */}
 


       
        </div>
  )
}

export default Makecontact