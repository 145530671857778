import React, { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faYoutube} from '@fortawesome/free-brands-svg-icons'
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons'


const Finaldet = () => {


  const [isPhoneScreen3, setIsPhoneScreen3] = useState(window.innerWidth <= 425);
  const [isPhoneScreen4, setIsPhoneScreen4] = useState(window.innerWidth <= 425);
  
  const handleResize = () => {
    setIsPhoneScreen3(window.innerWidth <=639);
    setIsPhoneScreen4(window.innerWidth <=650);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


   useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isPhoneScreen3,isPhoneScreen4]);



  const form = useRef();

  const [info, setinfo] = useState({name : "", email : "", message : ""})
const [errormes, seterrormes] = useState("")

  const sendEmail = (e) => {
    e.preventDefault();
if (info.name != "" && info.email != "" && info.message != "")
{
  emailjs
      .sendForm(
        "service_pe5hht6",
        "template_zgftpni",
        form.current,
        "L-N_ExUylATdXKtA5"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          seterrormes("")
          form.current.reset()
          alert("message sent")

        },
        (error) => {
          console.log(error.text);
        }
      );
}
else
{
  seterrormes("יש למלא את כל השדות")
  console.log("does not send")
}
    
  };

  

  return (
    <div>
        

{/*contact */}
{/* <h1 className='detback'>  לאימון ניסיון השאירו פרטים </h1> */}
<h2 className="text-center text-lg font-semibold leading-8 text-gray-900"
style={{backgroundColor : "#2c2b42", color : "red"}}>
        התחילו איתנו את המסע לאלופי נינג'ה
        </h2>
 <div className='contact-container'>
  {
    !isPhoneScreen4 && 
    <div className='right-side-text'> 
<iframe
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3391.8517048885424!2d34.70540612467657!3d31.774530374098617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1502bd92043d70fd%3A0x5e83c54e64f113c1!2z16DXmdeg15In15Qg16TXkNeV15XXqCDXktefINeZ15HXoNeU!5e0!3m2!1siw!2sil!4v1688724846098!5m2!1siw!2sil"
// width="600"
// height="350"
className='map'
style={{ border: 0 }}
allowFullScreen=""
loading="lazy"
referrerPolicy="no-referrer-when-downgrade"
title="Embedded Google Map"
></iframe>
  </div>
  }
  
  
  {/* <h2>  לאימון ניסיון השאירו פרטים </h2> */}
  
    <div className="form-content">
    <form ref={form} onSubmit={sendEmail}>
        <div className="form-group">
          <input type="text" id="name" name="to_name" placeholder='שם מלא' onChange={(e)=> setinfo({...info, name : e.target.value})}/>
        </div>
        <div className="form-group">
          <input type="email" id="email" name="from_name"  placeholder='אימייל' onChange={(e)=> setinfo({...info, email : e.target.value})} />
        </div>
     
        <div className="form-group">
          <textarea id="message" name="message"  placeholder='הודעה' onChange={(e)=> setinfo({...info, message : e.target.value})}></textarea>
        </div>

        &nbsp; &nbsp;&nbsp; &nbsp;
        <button type="submit" value="Send" className='bform' >צאו לדרך</button>
  
        <br/>
        <p style={{color : "red", backgroundColor : "white"}}>{errormes}</p>
        </form>
      </div>
  
   </div>

   

{/*final part */}

<div>
  
</div>


      <div className="mx-auto max-w-7xl px-6 lg:px-8" >
       
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">

        <a href='https://api.whatsapp.com/send?phone=972522257838&text=Hello%20there!'>
  <FontAwesomeIcon icon={faWhatsapp} style={{width : "50px", height : "50px", color : "green"}} />
  </a>

        <a href='https://www.facebook.com/profile.php?id=100076104404400'>
<FontAwesomeIcon icon={faFacebook} style={{width : "50px", height : "50px", color : "blue"}} />
</a>

<a href='https://www.instagram.com/ninjapower_ganyavne/'>
<FontAwesomeIcon icon={faInstagram} style={{width : "50px", height : "50px", color : "black"}} />
</a>

  <a href='https://www.youtube.com/watch?v=DeKX_kdKBYw&feature=youtu.be'> 
  <FontAwesomeIcon icon={faYoutube} style={{width : "50px", height : "50px", color : "red"}}/>
  </a>

{
  !isPhoneScreen3 &&
  <a href="https://www.google.com/maps/place/%D7%A0%D7%99%D7%A0%D7%92'%D7%94+%D7%A4%D7%90%D7%95%D7%95%D7%A8+%D7%92%D7%9F+%D7%99%D7%91%D7%A0%D7%94%E2%80%AD/@31.7745304,34.7054061,17z/data=!3m1!4b1!4m6!3m5!1s0x1502bd92043d70fd:0x5e83c54e64f113c1!8m2!3d31.7745304!4d34.7028312!16s%2Fg%2F11pklv56lh?hl=iw&entry=ttu" 
          target="_blank" rel="noopener noreferrer">
           <img
          style={{width : "75px", height : "75px"}}
          src="https://www.techgadgetscanada.com/wp-content/uploads/2017/05/Google-Maps-2-.png"
          alt="google maps"
         
        /> </a>
}

  
        </div>
      </div>
<br/>




    </div>



 
    
  )
}

export default Finaldet