const webstore = (state = {details : [], events : [], gympics : [], leaders : [], trainingspics : [], trainings : [], values : [], reviews : [] }, action)=>
{
    switch (action.type)
     {
        case "LOAD":
            return {...state, details : action.payload};

            case "LOAD2":
            return {...state, events : action.payload};

            case "LOAD3":
            return {...state, gympics : action.payload};

            case "LOAD4":
            return {...state, leaders : action.payload};

            case "LOAD5":
            return {...state, trainingspics : action.payload};

            case "LOAD6":
            return {...state, trainings : action.payload};

            case "LOAD7":
                return {...state, values : action.payload};

             case "LOAD8":
            return {...state, reviews : action.payload};
            
            
    
        default:
            return state
    }


}

export default webstore;