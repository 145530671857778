import React from 'react';
import {Link, Routes} from 'react-router-dom'
import './App.css'
import Main from './coms/Main';

function App() {

  

  return (
    <div>

     <Main/>

    </div>
  );
}

export default App;
