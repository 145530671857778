import React, { useRef, useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../Firebase';
import { useSelector } from 'react-redux';
import {Helmet} from 'react-helmet'

const Reviews = () => {

const stordata = useSelector(state => state.reviews)
const [rev, setrev] = useState({fname : "", lname : "", mes : ""})
const reviewdata = collection(db,"reviews");


const sendreview = async () =>
{
    await addDoc(reviewdata, {
        firstname : rev.fname,
        lastname : rev.lname,
        message : rev.mes,
        time : new Date(Date.now()).toString()
      })
      alert("ההודעה נשלחה")
}

  return (
    <div>
       <Helmet>
        <title>נינג'ה פאוור-העמוד שלכם</title>
        <meta name="description" content="נינג'ה פאוור-תשתפו אותנו בחוויות שלכם" />
     
      </Helmet>
        
       
          <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <img className="mx-auto h-16" src="https://live.staticflickr.com/65535/53062609412_dc2407d749_w.jpg" alt="" />
        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p  style={{fontSize : '30px', textShadow : "0 0 3px black" }}>
             העמוד שלכם - כמו שזה נשמע, העמוד הזה מוקדש לכם, כאן תוכלו לרשום לנו את דעתכם על המכון בכל נושא שתרצו, תוכלו לרשום לנו הצעות לשיפור, ביקורות, חוויות מאימונים ואירועים ואנחנו נתייחס להכל עם מלוא תשומת הלב
            </p>
            <br/> <br/> 
            <ArrowDownwardIcon style={{fontSize : "200px", color : "blue"}}/>
          </blockquote>
        </figure>
      </div>
    </section>
    
    <div className="isolate bg-white px-6 py-24 sm:py-5 lg:px-8">
      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      >
        <div
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">המקום לשתף</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          לא חובה להזדהות יש אפשרות גם להישאר בעילום שם
        </p>
      </div>
      <div className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900"  style={{textAlign : "right"}}>
              שם משפחה
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e)=> setrev({...rev, lname : e.target.value})}
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900"  style={{textAlign : "right"}}>
              שם פרטי
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="family-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e)=> setrev({...rev, fname : e.target.value})}
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900" style={{textAlign : "right"}}>
              הודעה
            </label>
            <div className="mt-2.5">
              <textarea
                name="message"
                id="message"
                rows={4}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e)=> setrev({...rev, mes : e.target.value})}
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
          onClick={sendreview}
            type="submit" style={{width : "300px"}} >
          שלח
          </button>
        </div>
      </div>
    </div>

    <br/> <br/> <br/> <br/> 

    <div className="bg-white py-5 sm:py-5">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" style={{textAlign : "right"}}>השיתופים</h2>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-3 lg:mx-0 lg:max-w-none lg:grid-cols-3">
           {stordata.map((x,index) => 
            {
              return  <div key={index}>
              <article className="flex max-w-xl flex-col items-start justify-between">
             <div className="flex items-center gap-x-4 text-xs">
               <time className="text-gray-500">
                 {x.time}
               </time>
             </div>
             <div className="group relative" >
               <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                   <span className="absolute inset-0" />
                   {x.firstname + " " + x.lastname}
               </h3>
               <p className="mt-5 line-clamp-3 text-sm leading-6 text-black-600">{x.message}</p>
             </div>
           </article>
           </div>
             
            })} 
           

         
        </div>
      </div>
    </div>

    <br/> <br/> 
   
        </div>
  )
}

export default Reviews